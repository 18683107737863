<template>
  <div>
    <el-breadcrumb separator-icon="ArrowRight" style="margin: 16px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 15px; min-height: calc(100vh - 111px)">
      <div>
        <!--    功能区-->
        <div style="margin: 10px 0">
          <!--    搜索区-->
          <div style="margin: 10px 0">
            <el-input v-model="search" clearable placeholder="请输入（暂未开放）" prefix-icon="Search" style="width: 20%"/>
            <el-button icon="Search" style="margin-left: 5px" type="primary" @click="load"></el-button>
            <el-button icon="refresh-left" style="margin-left: 10px" type="default" @click="reset"></el-button>
            <div style="float: right">
<!--              <el-tooltip content="添加" placement="top">-->
<!--                <el-button icon="plus" style="width: 50px" type="primary" @click="add"></el-button>-->
<!--              </el-tooltip>-->
              <el-button icon="document" style="margin-left: 10px" type="success" @click="exportTable">导出此页</el-button>

            </div>
          </div>
        </div>
        <!--    表格-->
        <el-table v-loading="loading" :data="tableData" border max-height="705" show-overflow-tooltip
                  style="width: 100%">
          <el-table-column label="#" type="index"/>
          <el-table-column label="月份" prop="creationtime" width="100"/>
          <!-- 在表头添加筛选器 -->
            <template slot-scope="{ column }">
              <el-date-picker
                v-model="creationtimeFilter"
                type="month"
                placeholder="筛选月份"
                format="yyyy-MM"
                style="width: 100%"
                @change="handleCreationtimeFilterChange"
              ></el-date-picker>
            </template>
          <el-table-column label="用户" prop="writers" width="100"/>
          <el-table-column label="姓名" prop="name" width="100"/>
          <el-table-column label="订单数" prop="ordernumber" width="100"/>
          <el-table-column label="提成总额" prop="payment" width="100"/>
          <el-table-column label="银行卡号" prop="yhnumber" width="100"/>
          <el-table-column label="支付宝账号" prop="zhifubao" width="100"/>
          <!--          <el-table-column label="结账状态" prop="remark" width="100"/>-->
          <el-table-column label="结算状态"
                           width="120px"
                           :filter-method="filterTag2"
                           :filters="[
              { text: '已结算', value: '已结算' },
              { text: '未结算', value: '未结算' },
            ]"
                           filter-placement="bottom-end"
                           prop="settlement"
                           sortable
          >
            <template #default="scope">
              <el-tag
                  :type="scope.row.settlement === '已结算' ? 'success' : 'info'"
                  disable-transitions
              >{{ scope.row.settlement }}
              </el-tag>
            </template>
          </el-table-column>
<!--                操作栏-->
          <el-table-column label="明细" width="150px" fixed="right">
            <template #default="scope">
              <el-button v-if="scope.row.settlement==='已结算' ||scope.row.settlement==='未结算'" icon="more-filled" type="default" @click="load1(scope.row)"></el-button>
            </template>
            <!--            <el-button v-if="scope.row.settlement!=='已结算' " icon="Edit" type="primary" @click="save(scope.row)"></el-button>-->
          </el-table-column>
          <el-table-column label="结算" width="150px" fixed="right">
            <template #default="scope">
              <el-button v-if="scope.row.settlement!=='已结算' " icon="Edit" type="primary" @click="save(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <div style="margin: 10px 0">
          <el-pagination
              v-model:currentPage="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20,100,500,1000,10000]"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <!--      弹窗-->
<!--        <div>-->
<!--          <el-dialog v-model="dialogVisible" title="查看" width="80%" >-->
<!--            <el-table v-loading="loading" :data="tableData1" border max-height="705" show-overflow-tooltip-->
<!--                      style="width: 100%">-->
<!--              <el-table-column label="#" type="index"/>-->

<!--              <el-table-column label="订单类型" prop="serve" width="100"/>-->
<!--              <el-table-column label="订单编号" prop="ordernumber" width="100"/>-->
<!--              <el-table-column label="客户id" prop="customerID" width="100"/>-->
<!--              <el-table-column label="订单创建时间" prop="creationtime" width="100"/>-->
<!--              <el-table-column label="审核时间" prop="deliverytime" width="100"/>-->
<!--              <el-table-column label="派单客服" prop="customer" width="100"/>-->
<!--              <el-table-column label="收费金额" prop="collection" width="100"/>-->
<!--              <el-table-column label="派单金额" prop="payment" width="100"/>-->
<!--              <el-table-column label="收款方式" prop="manner" width="100"/>-->


<!--              &lt;!&ndash;      操作栏&ndash;&gt;-->
<!--              <el-table-column label="驳回" width="130px">-->
<!--                <template #default="scope">-->
<!--                  <el-button icon="Edit" type="primary" @click="Bohui(scope.row)"></el-button>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--            <div style="margin: 10px 0">-->
<!--              &lt;!&ndash; 弹窗表格分页 &ndash;&gt;-->
<!--              <el-pagination-->
<!--                  v-model="currentPageDialog"-->
<!--                  :page-size="pageSizeDialog"-->
<!--                  :total="totalDialog"-->
<!--                  layout="total, sizes, prev, pager, next, jumper"-->
<!--                  @size-change="handleSizeChangeDialog"-->
<!--                  @current-change="handleCurrentChangeDialog"-->
<!--              >-->
<!--              </el-pagination>-->
<!--            </div>-->
<!--          </el-dialog>-->
<!--        </div>-->
      </div>
    </el-card>
  </div>
</template>
<script src="@/assets/js/adminzd.js">
</script>